#main-footer > div {
  display: flex;
  flex-direction: row;
  background: white;
  position: relative;
  bottom: 0px;
  height: 15vh;
}

#main-footer .left-button {
  width: 40%;
  border: 0px;
  background: white;
  padding-left: 25px;
  padding-right: 15px;
  text-align: left;
  font-weight: 300px;
  font-size: 18px;
  color: #172934;
  line-height: 26px;
}

#main-footer .right-button:hover{
  cursor: pointer;
}

#main-footer .right-button {
  border: 0px;
  color: white;
  background: #23c9a7;
  flex: 1;
  font-size: 18px;
  text-align: right;
  padding-right: 61px;
  padding-left: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.028rem;
}

.arrow {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}


.button-rounded-green:hover {
         cursor: pointer;
}
.button-rounded-green {
  border-radius: 26px;
  height: 52px;
  line-height: 52px;
  color: white;
  text-align:center;
  padding-left: 35px;
  padding-right: 35px;
  border: 0px;
  background: #00CDA5;
  font-size: 18px;
  font-weight: 700px;
}

.not-homepage-footer{
background: transparent;
display: flex;
justify-content: center;
align-items: center;
min-height: 135px;
}

.positioncenter {
  width: 50px;
  margin: 0 auto;
  text-align: center;;
}