#header-container {
  padding-top: 5vh;
  /* min-height: 336px; */
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 3vw;
}

.homepage-title {
  font-size: 42px;
  line-height: 50px;
  padding-top: 35px;
  font-weight: 800;
  margin-top: 0px;
  margin-bottom: 0px;
  letter-spacing: 0.15rem;
  color: #29292B;
}

.question-counter {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.1rem;
}

.current-page-counter {
  color: #29292B;
}
.maximum-page-counter {
  font-size: 12px;
}