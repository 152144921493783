.results-card {
  margin-top: 50px;
  text-align: center;
  border-radius: 11px;
  background: white;
  max-width: 78%;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.34);
  padding: 25px;
}

.results-card h1:not(.name-of-personality) {
  /* text-transform: uppercase; */
}
.results-card h1 {
  /* text-transform: uppercase; */
  text-align: center;
  color: #29292b;
}

.personality-image {

  /* border: 1px solid #29292b; */
}

.main.results-page {
  padding-top: 50px;
}        

.social-share-buttons{
  padding-top: 20px;
  /* width: 200px;  */
  margin: 0 auto;
  text-align: center;
}

.title-social-buttons {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.027rem;
}

.short-description {
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.062px;
  font-weight: 300;
}
.title-social-buttons {
  padding-bottom: 20px;
}
.social-spacing {
  position: relative;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
}

.social-group {
  padding-top:10px;
}

.name-of-personality {
  text-transform: lowercase;
}

.name-of-personality::first-letter {
  text-transform: capitalize;
}


.full-width {
  width: 100%;
}

@media (min-width: 768px) {     
  .full-width {
    width: 300px;
  }

  .personality-image {
    width: 300px;
  }
}

.personality-title-name {
  text-transform: capitalize !important;
}

.short-description {
  padding-top: 15px;
  padding-bottom: 15px;
}

.main.results-page{
  padding-top: 30px;
}
.results-card {
  margin-bottom: 30px;
}