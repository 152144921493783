.homepageWelcomeImage {
  float: right;
  /* padding-right: 4vw; */
  /* padding-top: 5vh; */
  /* padding-bottom: 5vh; */
  /* height: 0%; */
}

.homepage-content {
  background: transparent;
}

.content {
  background: white;
  color: #29292b;
}

.content button {
  border: 0px;
  background: transparent;
  font-size: 19px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 25px;
  padding-right: 10px;
}

.force-overflow {
  min-height: 450px;
}

#wrapper {
  text-align: center;
  margin: auto;
}

/*
 *  STYLE 1
 */

.list-of-answers {
  /* margin-left: 22px; */
  /* float: left; */
  /* height: 30vh; */
  /* width: 65px; */
  /* background: #F5F5F5; */
  overflow-y: scroll;
  /* margin-top: 40px; */
  /* margin-bottom: 40px; */
  /* margin-left: 25px; */
  position: relative;
  /* margin-right: 25px; */
  height: 100%;
  position: absolute;
  /* /* padding-top: 20px; */
}

.indicator {
  font-weight: bold;
  display: block;
  font-size: 0.8rem;
  color: #00d1a3;
  width: 100%;
  padding-bottom: 10px;
}

#style-1 button {
  color: #29292B;
  letter-spacing: 0.1rem;
  font-size: 19px;
  line-height: 27px;
  font-weight: 300;
}

#style-1 button.active {
  color: #23C9A7;
}

::-webkit-scrollbar {
  min-width: 1px;
  min-height: 1px;
  -webkit-overflow-scrolling: touch;

}

#style-1 {
  -webkit-overflow-scrolling: auto !important;
  margin: 25px;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
}

.button-rounded-green.disabled {
  background-color: #c9c9c9;
}

* {
  -webkit-overflow-scrolling: auto;
}

#main-footer button {
  margin: 0;
}
