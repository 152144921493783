@font-face {
  font-family: 'Font Awesome 5 Brands';
  src: url('./fonts/FontAwesome5Brands-Regular.eot');
  src: url('./fonts/FontAwesome5Brands-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/FontAwesome5Brands-Regular.woff2') format('woff2'),
      url('./fonts/FontAwesome5Brands-Regular.woff') format('woff'),
      url('./fonts/FontAwesome5Brands-Regular.ttf') format('truetype'),
      url('./fonts/FontAwesome5Brands-Regular.svg#FontAwesome5Brands-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  src: url('./fonts/FontAwesome5Brands-Regular_1.eot');
  src: url('./fonts/FontAwesome5Brands-Regular_1.eot?#iefix') format('embedded-opentype'),
      url('./fonts/FontAwesome5Brands-Regular_1.woff2') format('woff2'),
      url('./fonts/FontAwesome5Brands-Regular_1.woff') format('woff'),
      url('./fonts/FontAwesome5Brands-Regular_1.ttf') format('truetype'),
      url('./fonts/FontAwesome5Brands-Regular_1.svg#FontAwesome5Brands-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  src: url('./fonts/FontAwesome5Brands-Regular_2.eot');
  src: url('./fonts/FontAwesome5Brands-Regular_2.eot?#iefix') format('embedded-opentype'),
      url('./fonts/FontAwesome5Brands-Regular_2.woff2') format('woff2'),
      url('./fonts/FontAwesome5Brands-Regular_2.woff') format('woff'),
      url('./fonts/FontAwesome5Brands-Regular_2.ttf') format('truetype'),
      url('./fonts/FontAwesome5Brands-Regular_2.svg#FontAwesome5Brands-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  src: url('./fonts/FontAwesome5Brands-Regular_3.eot');
  src: url('./fonts/FontAwesome5Brands-Regular_3.eot?#iefix') format('embedded-opentype'),
      url('./fonts/FontAwesome5Brands-Regular_3.woff2') format('woff2'),
      url('./fonts/FontAwesome5Brands-Regular_3.woff') format('woff'),
      url('./fonts/FontAwesome5Brands-Regular_3.ttf') format('truetype'),
      url('./fonts/FontAwesome5Brands-Regular_3.svg#FontAwesome5Brands-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Medium.eot');
  src: url('./fonts/Jost-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-Medium.woff2') format('woff2'),
      url('./fonts/Jost-Medium.woff') format('woff'),
      url('./fonts/Jost-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-ThinItalic.eot');
  src: url('./fonts/Jost-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-ThinItalic.woff2') format('woff2'),
      url('./fonts/Jost-ThinItalic.woff') format('woff'),
      url('./fonts/Jost-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-LightItalic.eot');
  src: url('./fonts/Jost-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-LightItalic.woff2') format('woff2'),
      url('./fonts/Jost-LightItalic.woff') format('woff'),
      url('./fonts/Jost-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Regular.eot');
  src: url('./fonts/Jost-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-Regular.woff2') format('woff2'),
      url('./fonts/Jost-Regular.woff') format('woff'),
      url('./fonts/Jost-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-BlackItalic.eot');
  src: url('./fonts/Jost-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-BlackItalic.woff2') format('woff2'),
      url('./fonts/Jost-BlackItalic.woff') format('woff'),
      url('./fonts/Jost-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-MediumItalic.eot');
  src: url('./fonts/Jost-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-MediumItalic.woff2') format('woff2'),
      url('./fonts/Jost-MediumItalic.woff') format('woff'),
      url('./fonts/Jost-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-ExtraBold.eot');
  src: url('./fonts/Jost-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-ExtraBold.woff2') format('woff2'),
      url('./fonts/Jost-ExtraBold.woff') format('woff'),
      url('./fonts/Jost-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-SemiBoldItalic.eot');
  src: url('./fonts/Jost-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-SemiBoldItalic.woff2') format('woff2'),
      url('./fonts/Jost-SemiBoldItalic.woff') format('woff'),
      url('./fonts/Jost-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Light.eot');
  src: url('./fonts/Jost-Light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-Light.woff2') format('woff2'),
      url('./fonts/Jost-Light.woff') format('woff'),
      url('./fonts/Jost-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-ExtraLight.eot');
  src: url('./fonts/Jost-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-ExtraLight.woff2') format('woff2'),
      url('./fonts/Jost-ExtraLight.woff') format('woff'),
      url('./fonts/Jost-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-ExtraLightItalic.eot');
  src: url('./fonts/Jost-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-ExtraLightItalic.woff2') format('woff2'),
      url('./fonts/Jost-ExtraLightItalic.woff') format('woff'),
      url('./fonts/Jost-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Black.eot');
  src: url('./fonts/Jost-Black.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-Black.woff2') format('woff2'),
      url('./fonts/Jost-Black.woff') format('woff'),
      url('./fonts/Jost-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Italic.eot');
  src: url('./fonts/Jost-Italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-Italic.woff2') format('woff2'),
      url('./fonts/Jost-Italic.woff') format('woff'),
      url('./fonts/Jost-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-SemiBold.eot');
  src: url('./fonts/Jost-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-SemiBold.woff2') format('woff2'),
      url('./fonts/Jost-SemiBold.woff') format('woff'),
      url('./fonts/Jost-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-BoldItalic.eot');
  src: url('./fonts/Jost-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-BoldItalic.woff2') format('woff2'),
      url('./fonts/Jost-BoldItalic.woff') format('woff'),
      url('./fonts/Jost-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Thin.eot');
  src: url('./fonts/Jost-Thin.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-Thin.woff2') format('woff2'),
      url('./fonts/Jost-Thin.woff') format('woff'),
      url('./fonts/Jost-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Bold.eot');
  src: url('./fonts/Jost-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-Bold.woff2') format('woff2'),
      url('./fonts/Jost-Bold.woff') format('woff'),
      url('./fonts/Jost-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-ExtraBoldItalic.eot');
  src: url('./fonts/Jost-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Jost-ExtraBoldItalic.woff2') format('woff2'),
      url('./fonts/Jost-ExtraBoldItalic.woff') format('woff'),
      url('./fonts/Jost-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

.personality-image {
  width: 100%;
  /* height: 300px; */
  object-fit:cover;
  /* border-radius: 100%; */
}

.App {
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  /* max-width: 425px; */
  margin: 0 auto;
  height: 100%;
  
  max-height: 100vh;
}

* {
  font-family: Jost;
} 
body {
  font-family: "Jost";
  background: #F7F7F7; 
}

#content {
  flex: 1;
}

.question-title {
  font-size: 30px;
  line-height: 43px;
  letter-spacing: 0.1rem;
  color: #29292B;
}


html, body, #root, .main {
  max-height: 100%;
  height: 100%;
  width: 100% !important;
}

.homepage-title {
  font-size: 5vh !important;
}

.homepageWelcomeImage {
  max-height: 30vh;
  padding-top: 2vh;
  padding-right: 5vw;
  height: 100% !important;
}
.main .flex-wrapper {
  flex:1;
}
#content {
  height: 100%;
  position: relative;
  display: block;
  overflow: auto;
}

body {
  margin: 0;
  overscroll-behavior: none;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
  outline: none !important;
} 

.social-group {
  padding-bottom: 30px;
}

.hidden {
  font-size: 0px;
  visibility: hidden;
}


#style-1 button:hover {
  cursor: pointer;
}
#style-1 button {
  width: 100%;
}


.simplebar-track.simplebar-vertical {
  background: #F6F7F8 !important;  
  border-radius: 10px !important;
}

.simplebar-scrollbar:before {
  background: #00CDA5 !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
}

.simplebar-content {
  padding-right: 20px !important;
}

.homepage-content {
  text-align: center;
}

.main.page0 {
  background: white;
}

.homepageWelcomeImage {
  padding-right: 0px !important;
  max-height: 100vh;
    padding-top: 2vh;
    padding-right: 5vw;
    /* height: 100% !important; */
    width: 100%;
    height: auto !important;
}

.page5 .question-title {
  font-size: 24px;
  line-height: 27px;
}